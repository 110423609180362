<template lang="pug">
full-width-page
	v-row(justify="center" align="center")
		v-col(cols="12" style="max-width:500px")
			v-alert.mb-3(:value="showActivated" type="success" color="success" border="bottom") {{$t('page.login:account.activated')}}
			v-alert.mb-3(:value="error.show" type="error" color="error") {{error.message}}
			v-card.mt-6.mb-3(outlined)
				v-card-text.text--primary
					h1.mb-2.title.text-center {{$t('page.login:heading')}}
					validation-observer(v-slot="{ handleSubmit, invalid }")
						v-form(@submit.prevent="handleSubmit(onSubmit)" @keyup.native.enter="handleSubmit(onSubmit)" ref="form")
							validation-provider(v-slot="{errors, touched}" rules="required|email" :name="$t('page.login:email.field.label')" slim)
								v-text-field.mb-3(v-model="email" :label="$t('page.login:email.field.label')" required outlined hide-details="auto" :error-messages="touched ? errors : []")
							validation-provider(v-slot="{errors, touched}" rules="required" :name="$t('page.login:password.field.label')" slim)
								v-text-field.mb-3(v-model="password" type="password" :label="$t('page.login:password.field.label')" required outlined hide-details="auto"  :error-messages="touched ? errors : []")
							template(v-if="step === '2fa'")
								validation-provider.mb-3(v-slot="{errors, touched}" rules="required|digits:6" name="token" slim)
									v-text-field(v-model.trim="token" outlined label="2FA Token" autofocus :error-messages="touched ? errors : []" hide-details="auto")
							v-btn(type="submit" block color="accent" depressed :disabled="invalid") {{$t('page.login:login.button')}}

			div.text-center.mb-4
				nuxt-link.d-block(:to="localePath({name:'forgot'})") {{$t('page.login:forgot.password.link')}}
				nuxt-link.d-block(:to="localePath({name:'register'})") {{$t('page.login:register.link')}}


			h2.my-6.text-center.text-subtitle-1(v-if="$i18n.locale === 'nl-NL'") Of wil je inloggen op je verzekering?
			v-btn(:to="localePath({name: 'verzekering-login'})" outlined block color="primary") {{$t('page.login:login.insurance.button')}}

</template>

<script>
import gql from 'graphql-tag';

import FullWidthPage from '@/components/pages/full-width-page.vue';

export default {
	name: 'Login',
	components: {
		FullWidthPage
	},
	asyncData({ app, store, redirect }) {
		if (store.state.user.id) {
			redirect('/');
		}

		app.ads({});
		app.meta({
			title: app.i18n.t('page.login:heading', {
				site: 'wintersport.nl'
			}),
			description: app.i18n.t('page.login:meta_description'),
			canonical: `https://${
				app.store.state.localeDomains[app.i18n.locale]
			}/login`
		});
		app.breadcrumbs({});
	},
	data() {
		return {
			showActivated: false,
			email: '',
			password: '',
			token: '',
			error: {
				show: false,
				message: null
			},
			step: 'login'
		};
	},
	head() {
		return {
			...this.$structuredData()
		};
	},
	created() {
		if (this.$route.query.activated) {
			this.showActivated = true;
			this.showForm = true;
		}
	},
	methods: {
		async onSubmit() {
			const sessionToken = await this.$graphClient
				.mutate({
					mutation: gql`
						mutation ($input: createSessionInput!) {
							sessionToken: createSession(input: $input)
						}
					`,
					variables: {
						input: {
							email: this.email,
							password: this.password,
							token: this.token
						}
					}
				})
				.then(data => {
					if (data.errors) {
						switch (data.errors[0].extensions?.code) {
							case 'missing-2fa': {
								this.step = '2fa';

								break;
							}
							case 'invalid-credentials': {
								this.error = {
									show: true,
									message: this.$t('page.login:error.login.failed')
								};

								break;
							}
							case 'invalid-2fa': {
								this.error = {
									show: true,
									message: this.$t('page.login:error.login.2fa')
								};

								break;
							}
							default: {
								this.error = {
									show: true,
									message: this.$t('page.login:error.login.failed')
								};
								this.$toast.error(data.errors[0].message);
							}
						}
					}

					return data.data.sessionToken || null;
				})
				.catch(error => {
					this.$toast.error(error.message);
				});

			if (sessionToken) {
				await this.$apolloHelpers.onLogin(
					sessionToken,
					this.$i18n.locale.slice(0, 2)
				);
				await this.$store.dispatch('checkSession');
				window.location = this.$route.query.redirect
					? decodeURIComponent(this.$route.query.redirect)
					: '/';
			}
		}
	}
};
</script>
